import * as React from "react"
import Header from '../elements/Header';
import Footer from '../elements/Footer';
import Newsletter from '../elements/Newsletter';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SEO } from '../components/SeoNew';
import Navigation from "../components/Navigation"
import { PageContainer } from "../components/PageContainer"
import { Container } from "../components/Container"

const JobsPage = () => {
  return (<>
    <PageContainer>
      <Navigation />
      <Container className="mt-16 mb-16">
        <h1
          className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
          Jobs
        </h1>
      </Container>

      <div className="bg-ndna rounded-t-4xl py-16">
        <Container>
          <div
            className="rounded-md w-full bg-white px-4 py-4 shadow-md transition transform duration-500 cursor-pointer mt-4">
            <div className="flex flex-col justify-start">
              <div className="flex justify-between items-center w-96">
                <div
                  className="text-lg font-semibold text-bookmark-red flex space-x-1 items-center mb-2">
                  <svg className="w-7 h-7 text-gray-700" fill="currentColor" viewBox="0 0 20 20"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                          clipRule="evenodd" />
                    <path
                      d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                  </svg>
                  <span>Frontend Engineer Intern</span>
                </div>
              </div>
              <div className="text-sm text-gray-500 flex space-x-1 items-center">
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd" />
                </svg>
                <span>Remote, Earth 🌍</span>
              </div>
              <div className="mt-2">
                      <span
                        className="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">Javascript</span>
                <span
                  className="bg-emerald-100 text-emerald-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-emerald-200 dark:text-emerald-900">Vue</span>
              </div>
              <div>
                <div className="mt-5">
                  <button disabled
                          className="mr-2 my-1 uppercase tracking-wider px-2 text-red-600 border-red-600 hover:bg-red-600 hover:text-white border text-sm font-semibold rounded py-1 transition transform duration-500 cursor-pointer">Closed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <svg width="1920" height="259" viewBox="0 0 1920 259" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0 173L80 158C160 144 320 115 480 130C640 144 800 202 960 209C1120 216 1280 173 1440 173C1600 173 1760 216 1840 238L1920 259V0H1840C1760 0 1600 0 1440 0C1280 0 1120 0 960 0C800 0 640 0 480 0C320 0 160 0 80 0H0V173Z"
              fill="#2590eb" />
      </svg>

    </PageContainer>
    <Footer />
  </>)
};

export default JobsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <>
  <SEO title="Jobs" />
</>